<script setup lang="ts">
import Notification from '@frontend/ui-kit/src/components/Base/Notification.vue';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";

const store = useNotificationStore();

defineProps({
    isError: { type:Boolean, default: false }
});
</script>

<template>
    <v-app class="app">
        <v-layout class="layout">
            <slot />
            <Notification :messages="store.messages"/>
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    background-color: $color-grey-100;

    :deep(.v-application__wrap) {
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.layout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    
    @include responsive('tablet') { 
        flex-direction: column;
    }
}
</style>